.ctooltip {
    position: initial;
    display: flex;
  }
  
  .ctooltip .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 80%;
    // left: 50%;
    margin-left: -60px;
  }
  
  .ctooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  
  .ctooltip:hover .tooltiptext {
    visibility: visible;
  }